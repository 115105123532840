import styled from 'styled-components';

export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const Title = styled("h1")`
    font-weight: 800;
    font-size: 30px;
    margin-top: 8px;
    color: ${({theme }) => theme.text};

    @media (max-width: 961px) {
        margin: 50px 0 0 0;
    }
`;


export const ContainerHeader = styled("header")`
    width: 100%;
    height: 15vh;
    padding: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 961px) {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 20px;
        align-items: flex-start;
        padding: 20px 20px 0 20px;
    }
`;

export const ContainerContent = styled("section")`
    width: 100%;
    margin-top: 40px;
    display: flex;
    padding-left: 60px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 50px;

    @media (max-width: 961px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
    }
`;


export const ContainerPreview = styled("div")`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 42px;
`;

export const SubTitle = styled('h2')`
    font-style: normal;
    font-weight: 600;

    font-size: 30px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin: 0 30px 10px 0px;
    color: ${({theme }) => theme.text};
`;

export const SubTitleTwo = styled(SubTitle)`
    font-weight: 400;
`;

export const TextPreview = styled("div")`
    color: ${({theme }) => theme.text};
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const ContainerButtonPrimary = styled("div")`
    width: 100%;
    height: 15vh;
    padding: 60px;

    @media (max-width: 961px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ButtonAddClient = styled("button")`
    height: 49px;
    left: 778.36px;
    top: 760px;
    font-size: 1.1rem;
    font-weight: 800;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0.5px solid ${({theme }) => theme.text};
    border-radius: 8px;
    color:  ${({theme }) => theme.text};
`;


export const ContainerButton = styled('div')``;
