import { Route, Routes as ReactRouterRoutes } from 'react-router-dom'
import { CustomerRelationship } from '../pages/customerRelationship'
import { Dashboard } from '../pages/dashboard'
import { GenerateQRCode } from '../pages/generateQRCode'
import { Login } from '../pages/login'
import { SatisfactionForm } from '../pages/satisfactionForm'
import { SuccessSendForm } from '../pages/successSendForm'
import { ProtectedRoute } from './protectedRoute'

const Routes = () => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<Login />}></Route>
      <Route
        path="/customer-relationship"
        element={
          <ProtectedRoute>
            <CustomerRelationship />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/qrcode"
        element={
          <ProtectedRoute>
            <GenerateQRCode />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/success" element={<SuccessSendForm />}></Route>
      <Route path="/satisfaction-form" element={<SatisfactionForm />}></Route>
    </ReactRouterRoutes>
  )
}

export { Routes }
