import { ButtonCStyle } from './styles'
import { PropsButton } from './types'

export const Button = ({
  text,
  type,
  color,
  onHandler,
  width = '177.64px',
}: PropsButton) => {
  return (
    <ButtonCStyle $width={width} onClick={onHandler} $color={color} type={type}>
      {text}
    </ButtonCStyle>
  )
}
