import { db } from '../configFireBase'
import { collection, getDocs, query, where } from "firebase/firestore";

export const listCustomerSatisfactionForm = async (idUser: string): Promise<any> => {
    try {

        const usersCollectionRef = collection(db, 'customerSatisfaction')

        const q = query(usersCollectionRef, where('idUser', '==', idUser))

        const data = await getDocs(q)

        return data.docs.map((doc) => ({ ...doc.data() }))
      } catch (e) {
        return false
      }
}