import { createGlobalStyle } from "styled-components";

export const lightTheme = {
    background: '#fff',
    text: '#232341',
    navSelect: '#232341',
    buttonCard: '#232341',
    accordion: {
        header: '#f8f7f9',
        text: '#fff',
        background: '#fcfbff'
    },
    blockForm: {
        background: '#eeeeee'
    }
}

//primary palete
// export const lightTheme = {
//     background: '#FFFAF2',
//     text: '#4B5544',
//     navSelect: '#66715a',
//     buttonCard: '#69564D',
//     accordion: {
//         header: '#ede8e0',
//         text: '#fff',
//         background: '#fbf4ea'
//     },
//     blockForm: {
//         background: '#D9D9D9'
//     }
// }

export default createGlobalStyle`

#show-in-print, #show-in-print-two {
    display: none;
}

@media print {
    #not-show-in-print {
        display: none;
    }

    #show-in-print {
        display: block;
        margin: 50px 0 0px 0;
    }

    #show-in-print-two {
        display: block;
        line-height: 1.2;
        margin: 36px 0 60px 0;
    }

    #show-header-in-print {
        align-items: center;
        margin-top: 60px;
    }
}

body, html {
    height: 100%;

    font-family: 'Roboto', sans-serif;
    //palete primary
    /* background-color: #FFFAF2; */
    background: #fff;
    -webkit-font-smoothing: antialiased;
  }
`;

