import { Navigate } from 'react-router-dom'
import { isExpired, decodeToken } from 'react-jwt'

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const token = window.localStorage.getItem('token') ?? ''
  const decodedToken = decodeToken(token)
  const isTokenExpired = isExpired(token)

  if (!decodedToken || isTokenExpired) {
    return <Navigate to="/" replace />
  }

  return children
}
