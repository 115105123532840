import { useEffect, useState } from 'react'
import { FiBarChart, FiCopy } from 'react-icons/fi'
import { TbQrcode } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import copyValueToClipboard from './copyToClipboard'
import {
  ButtonAddClient,
  ButtonCustom,
  Card,
  Container,
  ContainerButton,
  ContainerButtonPrimary,
  ContainerCard,
  ContainerContent,
  ContainerHeader,
  ContainerPreview,
  SubTitle,
  TextPreview,
  Title,
  TitleCard,
} from './styles'
import { useTheme } from 'styled-components'

const CustomerRelationship = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [user, setUser] = useState('')

  useEffect(() => {
    const user: string = window.localStorage.getItem('user') ?? ''
    setUser(JSON.parse(user))
  }, [])

  const handlerClick = () => {
    toast.success('Link copiado com sucesso!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })

    copyValueToClipboard(
      `${process.env.REACT_APP_DOMAIN}/satisfaction-form?idUser=${user}`
    )
  }

  const redirectCreateClient = () => {
    navigate('/dashboard', {
      state: { idUser: user },
    })
  }

  const redirecQRCode = () => {
    navigate('/qrcode', {
      state: { idUser: user },
    })
  }

  const hanlderLogout = () => {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')

    navigate('/')
  }

  return (
    <Container>
      <ContainerHeader>
        <div>
          <ContainerPreview onClick={hanlderLogout}>
            <TextPreview>Sair</TextPreview>
          </ContainerPreview>
          <Title>Formulários</Title>
        </div>
        <img src="./logo-final.png" alt="logo" />
      </ContainerHeader>
      <ContainerButtonPrimary>
        <ButtonAddClient onClick={redirectCreateClient}>
          <FiBarChart color={theme.text} size={35} />
          <p style={{ marginLeft: 5 }}>Dashboard</p>
        </ButtonAddClient>
      </ContainerButtonPrimary>
      <ContainerContent>
        <ContainerCard>
          <Card>
            <div>
              <TitleCard>Formulário de Pesquisa de satisfação</TitleCard>
              <SubTitle>
                Esse formulário tem como objetivo analisar a satisfação do
                cliente durante o contato com sua marca
              </SubTitle>
            </div>
            <ContainerButton>
              <ButtonCustom onClick={handlerClick} type="button">
                <FiCopy size={20} /> Copiar link
              </ButtonCustom>
              <ButtonCustom onClick={redirecQRCode} type="button">
                <TbQrcode size={20} />
                Gerar QR code
              </ButtonCustom>
            </ContainerButton>
          </Card>
        </ContainerCard>
      </ContainerContent>
    </Container>
  )
}

export { CustomerRelationship }
