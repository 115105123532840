import {
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listCustomerSatisfactionForm } from '../../services/listAllSubscribed/listAllSubscribed'
import {
  Container,
  ContainerContent,
  ContainerGraficOne,
  ContainerHeader,
  ContainerItemNav,
  ContainerLoading,
  ContainerNav,
  ContainerPreview,
  ContainerStyle,
  ContainerTitleAndLogo,
  DetailsStyle,
  IconLoading,
  SummaryStyle,
  TextDetails,
  TextItemNav,
  TextNotData,
  TextObsClientNotData,
  TextPreview,
  Title,
  TitleGrafic,
} from './styles'
import { useTheme } from 'styled-components'
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar, Pie } from 'react-chartjs-2'
import { FiBarChart, FiMessageSquare } from 'react-icons/fi'
import { validateUserNumberOfOpcions } from '../../services/newFormat'

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

interface IDataGrafic {
  answerService: number[]
  companiesThatSellProduct: number[]
  productPriceAnswer: number[]
  responseFacilityEnvironment: number[]
  usedTheMostResponsiveServices: number[]
  wouldRecommendThisCompany: number[]
  obsClient: {
    text: string
    createAt: string
  }
}

const Dashboard = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [dataLength, setDataLength] = useState(0)
  const [dataObsClientAPI, setDataObsClientAPI] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [renderContent, setRenderContent] = useState<'charts' | 'suggestions'>(
    'charts'
  )

  const location = useLocation()
  const idUserLocation = location
  const { idUser } = idUserLocation.state

  const [answerServiceState, setAnswerServiceState] = useState<IDataGrafic>({
    answerService: [0, 0, 0, 0],
    companiesThatSellProduct: [0, 0, 0, 0],
    productPriceAnswer: [0, 0, 0, 0],
    responseFacilityEnvironment: [0, 0, 0, 0],
    usedTheMostResponsiveServices: [0, 0, 0, 0],
    wouldRecommendThisCompany: [],
    obsClient: { text: '', createAt: '' },
  })

  const serializeDataGraficBar = (
    data: IDataGrafic[],
    key: 'companiesThatSellProduct' | 'productPriceAnswer'
  ): number[] => {
    const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    data.forEach((ele) => {
      if (Number(ele[key]) === 1) {
        result[0] = result[0] + 1
      }

      if (Number(ele[key]) === 2) {
        result[1] = result[1] + 1
      }

      if (Number(ele[key]) === 3) {
        result[2] = result[2] + 1
      }

      if (Number(ele[key]) === 4) {
        result[3] = result[3] + 1
      }

      if (Number(ele[key]) === 5) {
        result[4] = result[4] + 1
      }

      if (Number(ele[key]) === 6) {
        result[5] = result[5] + 1
      }

      if (Number(ele[key]) === 7) {
        result[6] = result[6] + 1
      }

      if (Number(ele[key]) === 8) {
        result[7] = result[7] + 1
      }

      if (Number(ele[key]) === 9) {
        result[8] = result[8] + 1
      }

      if (Number(ele[key]) === 10) {
        result[9] = result[9] + 1
      }
    })

    return result
  }

  const serializeData = (
    data: IDataGrafic[],
    key:
      | 'answerService'
      | 'companiesThatSellProduct'
      | 'productPriceAnswer'
      | 'responseFacilityEnvironment'
      | 'usedTheMostResponsiveServices'
      | 'wouldRecommendThisCompany'
  ): number[] => {
    const result = [0, 0, 0, 0]

    data.forEach((ele) => {
      if (Number(ele[key]) <= 3) {
        result[0] = result[0] + 1
      }

      if (Number(ele[key]) > 3 && Number(ele[key]) <= 5) {
        result[1] = result[1] + 1
      }

      if (Number(ele[key]) > 5 && Number(ele[key]) <= 8) {
        result[2] = result[2] + 1
      }

      if (Number(ele[key]) > 8 && Number(ele[key]) <= 10) {
        result[3] = result[3] + 1
      }
    })

    return result
  }

  const getDataGraficOne = async () => {
    const idUserLocation = location
    const { idUser } = idUserLocation.state

    setLoading(true)
    const data: IDataGrafic[] = await listCustomerSatisfactionForm(idUser)

    if (typeof data === 'boolean' || !data) {
      setLoading(false)
      setDataLength(0)

      return
    }

    setLoading(false)
    setDataLength(data.length)

    const dataObsClient = data
      .filter((item) => item.obsClient && item.obsClient.text !== '')
      .map((item) => {
        return { text: item.obsClient.text, createAt: item.obsClient.createAt }
      })

    setDataObsClientAPI(dataObsClient)

    const dataCompaniesThatSellProduct = serializeDataGraficBar(
      data,
      'companiesThatSellProduct'
    )
    const dataProductPriceAnswer = serializeDataGraficBar(
      data,
      'productPriceAnswer'
    )

    const dataAnswerService = serializeData(data, 'answerService')
    const dataResponseFacilityEnvironment = serializeData(
      data,
      'responseFacilityEnvironment'
    )
    const dataUsedTheMostResponsiveServices = serializeData(
      data,
      'usedTheMostResponsiveServices'
    )
    const dataWouldRecommendThisCompany = serializeData(
      data,
      'wouldRecommendThisCompany'
    )

    setAnswerServiceState({
      answerService: dataAnswerService,
      companiesThatSellProduct: dataCompaniesThatSellProduct,
      productPriceAnswer: dataProductPriceAnswer,
      responseFacilityEnvironment: dataResponseFacilityEnvironment,
      usedTheMostResponsiveServices: dataUsedTheMostResponsiveServices,
      wouldRecommendThisCompany: dataWouldRecommendThisCompany,
      obsClient: { text: '', createAt: '' },
    })
  }

  useEffect(() => {
    getDataGraficOne()
  }, [])

  const rest = {
    backgroundColor: ['#ef407c', '#f98650', '#629df5', '#23daaf'],
    // primary palete
    // backgroundColor: ['rgba(75,192,192,1)', '#ecf0f1', '#50AF95', '#f3ba2f'],
    borderColor: theme.text,
    borderWidth: 2,
  }

  const Charts = () => {
    return (
      <>
        {dataLength === 0 ? (
          <TextNotData>
            Nenhum cliente preencheu ainda o formulário de satisfação.
          </TextNotData>
        ) : (
          <>
            <ContainerGraficOne>
              <TitleGrafic>
                Satisfação de seu cliente em relação ao seu atendimento
              </TitleGrafic>
              <Pie
                options={{
                  plugins: {},
                }}
                data={{
                  labels: ['Pessimo', 'Ruim', 'Bom', 'Ótimo'],
                  datasets: [
                    {
                      type: 'pie',
                      label: 'Quantidades de votos',
                      data: answerServiceState.answerService,
                      ...rest,
                    },
                  ],
                }}
              />
            </ContainerGraficOne>

            {validateUserNumberOfOpcions(idUser) ? (
              <div></div>
            ) : (
              <ContainerGraficOne>
                <TitleGrafic>
                  Ao ser perguntado qual posição sua empresa ficaria em um
                  ranking de 0 a {validateUserNumberOfOpcions(idUser) ? 5 : 10},
                  o seus clientes respondeu
                </TitleGrafic>
                <Bar
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],

                    datasets: [
                      {
                        type: 'bar',
                        label: 'Quantidade de votos',
                        data: answerServiceState.companiesThatSellProduct,
                        ...rest,
                      },
                    ],
                  }}
                />
              </ContainerGraficOne>
            )}

            <ContainerGraficOne>
              <TitleGrafic>
                Avaliação de seus clientes em relação ao ambiente de seu
                estabelecimento
              </TitleGrafic>
              <Pie
                options={{
                  plugins: {},
                }}
                data={{
                  labels: ['Pessimo', 'Ruim', 'Bom', 'Ótimo'],
                  datasets: [
                    {
                      type: 'pie',
                      label: 'Quantidades de votos',
                      data: answerServiceState.responseFacilityEnvironment,
                      ...rest,
                    },
                  ],
                }}
              />
            </ContainerGraficOne>

            <ContainerGraficOne>
              <TitleGrafic>
                Ao ser perguntado o quanto ele recomendaria a sua empresa, o
                seus clientes respondeu
              </TitleGrafic>
              <Bar
                options={{
                  plugins: {},
                }}
                data={{
                  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                  datasets: [
                    {
                      type: 'bar',
                      label: 'Quantidades de votos',
                      data: answerServiceState.productPriceAnswer,
                      ...rest,
                    },
                  ],
                }}
              />
            </ContainerGraficOne>

            <ContainerGraficOne>
              <TitleGrafic>
                Avaliação de seus clientes em relação ao preço do seu produto.
              </TitleGrafic>
              <Pie
                options={{
                  plugins: {},
                }}
                data={{
                  labels: ['Pessimo', 'Ruim', 'Bom', 'Ótimo'],
                  datasets: [
                    {
                      type: 'pie',
                      label: 'Quantidades de votos',
                      data: answerServiceState.usedTheMostResponsiveServices,
                      ...rest,
                    },
                  ],
                }}
              />
            </ContainerGraficOne>
          </>
        )}
      </>
    )
  }

  const Suggestions = () => {
    return (
      <ContainerStyle>
        {dataObsClientAPI.length === 0 ? (
          <TextObsClientNotData>
            Nenhum cliente preencheu o campo observações.
          </TextObsClientNotData>
        ) : (
          <>
            {dataObsClientAPI.map(
              (item: { text: string; createAt: string }) => {
                return (
                  <DetailsStyle>
                    <SummaryStyle>Data de envio: {item?.createAt}</SummaryStyle>
                    <TextDetails>{item?.text}</TextDetails>
                  </DetailsStyle>
                )
              }
            )}
          </>
        )}
      </ContainerStyle>
    )
  }

  const mapperContent: { [key: string]: React.ReactNode } = {
    charts: <Charts />,
    suggestions: <Suggestions />,
  }

  if (loading) {
    return (
      <ContainerLoading>
        <IconLoading />
      </ContainerLoading>
    )
  }

  return (
    <Container>
      <ContainerHeader>
        <ContainerTitleAndLogo>
          <div>
            <ContainerPreview
              onClick={() => navigate('/customer-relationship')}
            >
              <TextPreview>Voltar</TextPreview>
            </ContainerPreview>
            <Title>Dashboard</Title>
          </div>
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => navigate('/customer-relationship')}
          >
            <img src="./logo-final.png" alt="logo" />
          </div>
        </ContainerTitleAndLogo>
        <ContainerNav>
          <ContainerItemNav
            select={renderContent === 'charts'}
            onClick={() => setRenderContent('charts')}
          >
            <FiBarChart
              color={
                renderContent === 'charts'
                  ? `${theme.background}`
                  : `${theme.navSelect}`
              }
              size={30}
            />
            <TextItemNav select={renderContent === 'charts'}>
              Gráficos
            </TextItemNav>
          </ContainerItemNav>
          <ContainerItemNav
            select={renderContent === 'suggestions'}
            onClick={() => setRenderContent('suggestions')}
          >
            <FiMessageSquare
              color={
                renderContent === 'suggestions'
                  ? '#FFFAF2'
                  : `${theme.navSelect}`
              }
              size={30}
            />
            <TextItemNav select={renderContent === 'suggestions'}>
              Sugestões/Elogios de seus clientes
            </TextItemNav>
          </ContainerItemNav>
        </ContainerNav>
      </ContainerHeader>
      <ContainerContent>{mapperContent[renderContent]}</ContainerContent>
    </Container>
  )
}

export { Dashboard }
