import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'
import { registerCustomerSatisfactionForm } from '../../services/registerSubscribed/registerSubscribed'
import {
  Container,
  ContainerContent,
  ContainerHeader,
  ContainerNumber,
  ContainerSliceNumber,
  IconLoading,
  Item,
  SubTitleItem,
  TextAreaStyle,
} from './styles'
import {
  listMockFour,
  listMockOne,
  listMockSix,
  listMockThree,
  listMockTwo,
} from './ultis'
import { useTheme } from 'styled-components'
import { validateUserNumberOfOpcions } from '../../services/newFormat'

const SatisfactionForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const idUserLocation = location
  const [_, idUser] = idUserLocation.search.split('=')

  const [loading, setLoading] = useState(false)

  const [textAreaValue, setTextAreaValue] = useState('')

  const [listOne, setListOne] = useState(() => {
    if (validateUserNumberOfOpcions(idUser)) {
      return listMockOne.splice(0, 5)
    }

    return listMockOne
  })

  const [listTwo, setListTwo] = useState(() => {
    if (validateUserNumberOfOpcions(idUser)) {
      return listMockTwo.splice(0, 5)
    }

    return listMockTwo
  })

  const [listThree, setListThree] = useState(() => {
    if (validateUserNumberOfOpcions(idUser)) {
      return listMockThree.splice(0, 5)
    }

    return listMockThree
  })

  const [listFour, setListFour] = useState(() => {
    if (validateUserNumberOfOpcions(idUser)) {
      return listMockFour.splice(0, 5)
    }

    return listMockFour
  })

  const [listSix, setListSix] = useState(() => {
    if (validateUserNumberOfOpcions(idUser)) {
      return listMockSix.splice(0, 5)
    }

    return listMockSix
  })

  const [indicateOne, setIndicateOne] = useState('')
  const [indicateTwo, setIndicateTwo] = useState('')
  const [indicateThree, setIndicateThree] = useState('')
  const [indicateFour, setIndicateFour] = useState('')
  const [indicateSix, setIndicateSix] = useState('')

  const [numberOpcion, setNumberOpcion] = useState<boolean>(false)

  const selectBlock = (
    selectNumber: string,
    list: any,
    setState: any,
    setIndicate: any
  ) => {
    setIndicate(String(selectNumber))

    const result = list.map((ele: any) => {
      if (ele.text === selectNumber) {
        ele.select = true

        return ele
      }

      ele.select = false
      return ele
    })

    setState(result)
  }

  const handlerSubmit = async () => {
    setLoading(true)

    const isValid = validateUserNumberOfOpcions(idUser)
      ? indicateOne !== '' &&
        indicateTwo !== '' &&
        indicateThree !== '' &&
        indicateFour !== ''
      : indicateOne !== '' &&
        indicateTwo !== '' &&
        indicateThree !== '' &&
        indicateFour !== '' &&
        indicateSix !== ''

    if (isValid) {
      try {
        const idUserLocation = location
        const [_, idUser] = idUserLocation.search.split('=')

        const payload = {
          idUser: idUser,
          answerService: indicateOne,
          responseFacilityEnvironment: indicateTwo,
          productPriceAnswer: indicateThree,
          usedTheMostResponsiveServices: indicateFour,
          companiesThatSellProduct: indicateSix,
          obsClient: {
            text: textAreaValue,
            createAt: new Date().toLocaleDateString('pt-BR'),
          },
        }

        const result = await registerCustomerSatisfactionForm(payload)

        if (!result) {
          setLoading(false)
          toast.error(
            'Não foi possivél enviar as suas respostas, tente novamente!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          )

          return
        }

        setIndicateOne('')
        setIndicateTwo('')
        setIndicateThree('')
        setIndicateFour('')
        setIndicateSix('')

        setListOne(listMockOne)
        setListTwo(listMockTwo)
        setListThree(listMockThree)
        setListFour(listMockFour)
        setListSix(listMockSix)

        navigate('/success')

        setLoading(false)
        toast.success('Respostas enviadas com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        })

        return
      } catch {
        setLoading(false)
        toast.error(
          'Não foi possivél enviar as suas respostas, tente novamente!',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          }
        )

        return
      }
    }

    toast.error('Campos obrigatórios não preenchidos', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })

    setLoading(false)
  }

  useEffect(() => {
    const idUserLocation = location
    const [_, idUser] = idUserLocation.search.split('=')

    const result = validateUserNumberOfOpcions(idUser)

    setNumberOpcion(result)
  }, [])

  return (
    <Container>
      <ContainerHeader>
        <img src="./logo-final.png" alt="logo" />
      </ContainerHeader>
      <ContainerContent>
        <p style={{ color: theme.text }}>* Campos Obrigatórios</p>

        <div>
          <SubTitleItem>
            * Em uma escala de 1 a {numberOpcion ? 5 : 10}, qual nota você daria
            para o atendimento que recebeu?
          </SubTitleItem>
          <ContainerSliceNumber>
            {listOne.map((ele, index) => {
              return (
                <ContainerNumber
                  key={index}
                  $border={ele.select}
                  onClick={() =>
                    selectBlock(ele.text, listOne, setListOne, setIndicateOne)
                  }
                >
                  {ele.text}
                </ContainerNumber>
              )
            })}
          </ContainerSliceNumber>
        </div>

        <div>
          <SubTitleItem>
            * Em uma escala de 1 a {numberOpcion ? 5 : 10}, como você avalia o
            ambiente do estabelecimento?
          </SubTitleItem>
          <ContainerSliceNumber>
            {listTwo.map((ele, index) => {
              return (
                <ContainerNumber
                  key={index}
                  $border={ele.select}
                  onClick={() =>
                    selectBlock(ele.text, listTwo, setListTwo, setIndicateTwo)
                  }
                >
                  {ele.text}
                </ContainerNumber>
              )
            })}
          </ContainerSliceNumber>
        </div>

        <div>
          <SubTitleItem>
            * Em uma escala de 1 a {numberOpcion ? 5 : 10}, como você avalia o
            preço do produto que adquiriu?
          </SubTitleItem>
          <ContainerSliceNumber>
            {listFour.map((ele, index) => {
              return (
                <ContainerNumber
                  onClick={() =>
                    selectBlock(
                      ele.text,
                      listFour,
                      setListFour,
                      setIndicateFour
                    )
                  }
                  key={index}
                  $border={ele.select}
                >
                  {ele.text}
                </ContainerNumber>
              )
            })}
          </ContainerSliceNumber>
        </div>

        <div>
          <SubTitleItem>
            * Em uma escala de 1 a {numberOpcion ? 5 : 10}, o quanto você
            recomendaria essa empresa para amigos / familiares?
          </SubTitleItem>
          <ContainerSliceNumber>
            {listThree.map((ele, index) => {
              return (
                <ContainerNumber
                  key={index}
                  $border={ele.select}
                  onClick={() =>
                    selectBlock(
                      ele.text,
                      listThree,
                      setListThree,
                      setIndicateThree
                    )
                  }
                >
                  {ele.text}
                </ContainerNumber>
              )
            })}
          </ContainerSliceNumber>
        </div>

        <div>
          {validateUserNumberOfOpcions(idUser) ? (
            <div></div>
          ) : (
            <>
              <SubTitleItem>
                * Dentro de um ranking de 1 a {numberOpcion ? 5 : 10} de
                empresas que vendem o mesmo produto ou presta o mesmo serviço
                qual posição estaria{' '}
                {numberOpcion ? 'Varanda Restaurante' : 'empresa'}.
              </SubTitleItem>
              <ContainerSliceNumber>
                {listSix.map((ele, index) => {
                  return (
                    <ContainerNumber
                      key={index}
                      $border={ele.select}
                      onClick={() =>
                        selectBlock(
                          ele.text,
                          listSix,
                          setListSix,
                          setIndicateSix
                        )
                      }
                    >
                      {ele.text}
                    </ContainerNumber>
                  )
                })}
              </ContainerSliceNumber>
            </>
          )}
        </div>

        <div>
          <SubTitleItem>
            Você tem algum elogio, sugestão ou reclamação que deseja nos
            informar? (Opcional)
          </SubTitleItem>
          <ContainerSliceNumber>
            <TextAreaStyle
              onChange={(e) => setTextAreaValue(e?.target?.value)}
              cols={2}
              rows={8}
            />
          </ContainerSliceNumber>
        </div>

        <Item>
          <Button
            onHandler={handlerSubmit}
            color={theme.buttonCard}
            type="button"
            text={loading ? <IconLoading /> : 'Enviar'}
          />
        </Item>
      </ContainerContent>
    </Container>
  )
}

export { SatisfactionForm }
