import { db } from '../configFireBase'
import { collection, addDoc, } from "firebase/firestore";

export const registerClient = async (data: any): Promise<boolean> => {
    try {
        await addDoc(collection(db, "clients"), data);

        return true
    } catch (e) {
        return false
    }
}


export const registerCustomerSatisfactionForm = async (data: any): Promise<boolean> => {
    try {
        await addDoc(collection(db, "customerSatisfaction"), data);

        return true
    } catch (e) {
        return false
    }
}
