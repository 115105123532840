import styled from 'styled-components';

export const ButtonCStyle = styled("button")<{$color: string | undefined, $width: string}>`
    min-width: ${({  $width }) =>  $width &&  $width};
    height: 49px;
    left: 778.36px;
    top: 760px;
    cursor: pointer;
    font-size: 1.3rem;

    background: ${({ $color, theme }) => $color ? $color : theme.text};
    border: 0.5px solid ${({theme }) => theme.background};
    border-radius: 8px;
    color:  ${({theme }) => theme.background};
`;