import { useFormik } from 'formik'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Button } from '../../components/button'
import { Input } from '../../components/input'
import { auth } from '../../services/configFireBase'
import {
  Container,
  ContainerButton,
  ContainerContent,
  ContainerHeader,
  Form,
  IconLoading,
  SubTitle,
  Title,
} from './styles'

const Login = () => {
  const navigate = useNavigate()

  const [signInWithEmailAndPassword, user, loadingAuth, error] =
    useSignInWithEmailAndPassword(auth)

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().required('Campo Obrigatório'),
      password: yup.string().required('Campo Obrigatório'),
    }),
    onSubmit: handleSubmit,
  })

  async function handleSubmit() {
    try {
      const userResult: any = await signInWithEmailAndPassword(
        form.values.email.trim(),
        form.values.password.trim()
      )

      form.resetForm()

      if (!userResult) {
        toast.error('Email ou senha estão icorretos!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        })
        return
      }

      navigate('/customer-relationship')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')

      window.localStorage.setItem(
        'token',
        JSON.stringify(userResult?.user.accessToken)
      )
      window.localStorage.setItem(
        'user',
        JSON.stringify(`${userResult?.user?.uid}`)
      )

      toast.success('Login realizado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })
    } catch {
      form.resetForm()
      toast.error('Não foi possivél realizar login!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })
    }
  }

  const validateOfError = ({ errors, touched }: any): boolean => {
    return errors && touched && errors
  }

  return (
    <Container>
      <ContainerHeader>
        <img src="./logo-final.png" alt="logo" />
      </ContainerHeader>
      <ContainerContent>
        <Title>Bem-vindo</Title>
        <SubTitle>Para entrar, é preciso fazer o login</SubTitle>

        <Form onSubmit={form.handleSubmit}>
          <Input
            label="Email"
            placeholder=""
            type="text"
            {...form.getFieldProps('email')}
            error={validateOfError({
              errors: form.errors.email,
              touched: form.touched.email,
            })}
          />

          <Input
            label="Senha"
            placeholder=""
            type="text"
            {...form.getFieldProps('password')}
            error={validateOfError({
              errors: form.errors.password,
              touched: form.touched.password,
            })}
          />

          <ContainerButton>
            <Button
              type="submit"
              text={loadingAuth ? <IconLoading /> : 'Entrar'}
            />
          </ContainerButton>
        </Form>
      </ContainerContent>
    </Container>
  )
}

export { Login }
