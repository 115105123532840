import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/reset.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import Global from './styles/global'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Analytics } from '@vercel/analytics/react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />

      <Global />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Analytics mode="production" />
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
