import { Routes } from './router'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from './styles/global'

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Routes />
    </ThemeProvider>
  )
}

export default App
