import styled from 'styled-components';


export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerPreview = styled("div")`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 42px;
`;

export const TextPreview = styled("div")`
    color: ${({theme }) => theme.text};
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const Title = styled("h1")`
    font-weight: 800;
    font-size: 30px;
    margin-top: 8px;
    color: ${({theme }) => theme.text};

    @media (max-width: 961px) {
            margin: 15px 0 0 0;
    }
`;

export const ContainerCard = styled("div")`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1035px) {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 20px;
        padding: 0;
        margin-top: 20px;
        width: 100%;
        align-items: flex-start;
        display: flex;
    }

`;


export const ContainerHeader = styled("header")`
    width: 100%;
    height: 15vh;
    padding: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 961px) {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 20px;
        align-items: flex-start;
        padding: 20px 0 0 34px;
    }
`;

export const ContainerContent = styled("section")`
    width: 100%;
    margin-top: 40px;
    display: flex;
    padding-left: 60px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 50px;

    @media (max-width: 961px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -25px
    }
`;


export const ButtonCustom = styled('button')`
    cursor: pointer;
    font-size: 1.1em;
    border: 0.5px solid ${({theme }) => theme.background};
    border-radius: 8px;
    color:  ${({theme }) => theme.background};
    margin-right: 10px;
    background: ${({theme }) => theme.buttonCard};
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 610px) {
        width: 100%;
        margin-top: 10px;
    }
`;

export const ContainerButtonPrimary = styled("div")`
    width: 100%;
    height: 15vh;
    padding: 60px;

    @media (max-width: 961px) {
        padding: 60px 0 0 34px;
    }
`;

export const ButtonAddClient = styled("button")`
    height: 49px;
    left: 778.36px;
    top: 760px;
    font-size: 1.1rem;
    font-weight: 800;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0.5px solid ${({theme }) => theme.text};
    border-radius: 8px;
    color:  ${({theme }) => theme.text};
`;

export const Card = styled('div')`
    background: ${({theme }) => theme.background};
    display: flex;
    width: 549px;
    border: 1px solid ${({theme }) => theme.text};
    border-radius: 8px;
    padding: 25px;
    margin: 15px 15px 0 0;
    flex-direction: column;
    justify-content: space-around;
    min-height: 352px;

    @media (max-width: 961px) {
        width: 90%;
    }
`;

export const TitleCard = styled('h1')`
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: ${({theme }) => theme.text};
    margin: 0 0 20px 0;
`;

export const SubTitle = styled('h2')`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    color: ${({theme }) => theme.text};
`;

export const ContainerButton = styled("div")`
    display: flex;

    @media (max-width: 961px) {
        flex-direction: column;
    }
`;