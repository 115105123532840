export const listMockOne = [
    {
      text: '1',
      select: false,
    },
    {
      text: '2',
      select: false,
    },
    {
      text: '3',
      select: false,
    },
    {
      text: '4',
      select: false,
    },
    {
      text: '5',
      select: false,
    },
    {
      text: '6',
      select: false,
    },
    {
      text: '7',
      select: false,
    },
    {
      text: '8',
      select: false,
    },
    {
      text: '9',
      select: false,
    },
    {
      text: '10',
      select: false,
    },
  ]

  export const listMockTwo = [
    {
      text: '1',
      select: false,
    },
    {
      text: '2',
      select: false,
    },
    {
      text: '3',
      select: false,
    },
    {
      text: '4',
      select: false,
    },
    {
      text: '5',
      select: false,
    },
    {
      text: '6',
      select: false,
    },
    {
      text: '7',
      select: false,
    },
    {
      text: '8',
      select: false,
    },
    {
      text: '9',
      select: false,
    },
    {
      text: '10',
      select: false,
    },

  ]

  export const listMockThree = [
    {
        text: '1',
        select: false,
      },
      {
        text: '2',
        select: false,
      },
      {
        text: '3',
        select: false,
      },
      {
        text: '4',
        select: false,
      },
      {
        text: '5',
        select: false,
      },
      {
        text: '6',
        select: false,
      },
      {
        text: '7',
        select: false,
      },
      {
        text: '8',
        select: false,
      },
      {
        text: '9',
        select: false,
      },
      {
        text: '10',
        select: false,
      },
  ]

  export const listMockFour = [
    {
        text: '1',
        select: false,
      },
      {
        text: '2',
        select: false,
      },
      {
        text: '3',
        select: false,
      },
      {
        text: '4',
        select: false,
      },
      {
        text: '5',
        select: false,
      },
      {
        text: '6',
        select: false,
      },
      {
        text: '7',
        select: false,
      },
      {
        text: '8',
        select: false,
      },
      {
        text: '9',
        select: false,
      },
      {
        text: '10',
        select: false,
      },
  ]

export const listMockFive = [
    {
        text: '1',
        select: false,
      },
      {
        text: '2',
        select: false,
      },
      {
        text: '3',
        select: false,
      },
      {
        text: '4',
        select: false,
      },
      {
        text: '5',
        select: false,
      },
      {
        text: '6',
        select: false,
      },
      {
        text: '7',
        select: false,
      },
      {
        text: '8',
        select: false,
      },
      {
        text: '9',
        select: false,
      },
      {
        text: '10',
        select: false,
      },
]

export const listMockSix = [
    {
      text: '1',
      select: false,
    },
    {
      text: '2',
      select: false,
    },
    {
      text: '3',
      select: false,
    },
    {
      text: '4',
      select: false,
    },
    {
      text: '5',
      select: false,
    },
    {
      text: '6',
      select: false,
    },
    {
      text: '7',
      select: false,
    },
    {
      text: '8',
      select: false,
    },
    {
      text: '9',
      select: false,
    },
    {
      text: '10',
      select: false,
    },

  ]
