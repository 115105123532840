import { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'

import { useNavigate } from 'react-router-dom'
import {
  ButtonAddClient,
  Container,
  ContainerButtonPrimary,
  ContainerContent,
  ContainerHeader,
  ContainerPreview,
  SubTitle,
  SubTitleTwo,
  TextPreview,
  Title,
} from './styles'
import { TbQrcode } from 'react-icons/tb'
import { useTheme } from 'styled-components'

const GenerateQRCode = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [user, setUser] = useState('')

  useEffect(() => {
    const user: string = window.localStorage.getItem('user') ?? ''
    setUser(JSON.parse(user))
  }, [])

  return (
    <Container>
      <ContainerHeader id="show-header-in-print">
        <div style={{ textAlign: 'center' }}>
          <ContainerPreview
            id="not-show-in-print"
            onClick={() => navigate('/customer-relationship')}
          >
            <TextPreview>Voltar</TextPreview>
          </ContainerPreview>
          <Title id="not-show-in-print">
            QR Code do formulário de satisfação
          </Title>
          <SubTitle id="show-in-print">
            Sua experiência em primeiro lugar!
          </SubTitle>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/customer-relationship')}
        >
          <img src="./logo-final.png" alt="logo" />
        </div>
      </ContainerHeader>
      <ContainerButtonPrimary id="not-show-in-print">
        <ButtonAddClient onClick={() => window.print()}>
          <TbQrcode color={theme.text} size={20} />
          <p style={{ marginLeft: 5 }}>Imprimir QR Code</p>
        </ButtonAddClient>
      </ContainerButtonPrimary>
      <ContainerContent>
        <div>
          <QRCode
            value={`${process.env.REACT_APP_DOMAIN}/satisfaction-form?idUser=${user}`}
            bgColor="#fff"
            fgColor="#0f0b24"
            size={300}
          />
        </div>
        <div>
          <SubTitleTwo id="show-in-print-two">
            Leia o QR code para avaliar o seu atendimento!
          </SubTitleTwo>
        </div>
      </ContainerContent>
    </Container>
  )
}

export { GenerateQRCode }
