import styled from "styled-components";
import { BiLoaderCircle } from "react-icons/bi"

export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerHeader = styled("header")`
    width: 100%;
    height: 15vh;
    padding: 40px;
`;

export const ContainerContent = styled("section")`
    width: 100%;
    height: 85vh;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

export const Title = styled("h1")`
    font-weight: 800;
    font-size: 35px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;
    color: ${({theme }) => theme.text};
`;

export const SubTitle = styled("p")`
    font-weight: 600;
    text-align: center;
    font-size: 22px;
    color: ${({theme }) => theme.text};
    margin-bottom: 70px;

    @media (max-width: 680px) {
        margin-bottom: 50px;
    }
`;

export const Form = styled("form")`
    min-width: 430px;

    @media (max-width: 680px) {
        min-width: 90%;
    }
`;

export const ContainerButton = styled("div")`
    width: 100%;
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
`;

export const IconLoading = styled(BiLoaderCircle)`
    width: 30px;
    height: 30px;
    margin-top: 5px;
    color: ${({theme }) => theme.background};
    animation: loading 2s linear infinite;

    @keyframes loading {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

