import styled, { css } from 'styled-components';
import { BiLoaderCircle } from 'react-icons/bi'

export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerPreview = styled("div")`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 42px;
`;

export const TextPreview = styled("div")`
    color: ${({theme }) => theme.text};
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const ContainerDashboardSecondary = styled("div")`

    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 20px 60px;

    @media (max-width: 961px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
export const TextObsClientNotData = styled("div")`
    color: ${({theme }) => theme.text};
    font-weight: 400;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 961px) {
        margin: 0 20px 0 0;
        margin-top: 15px;
    }
`;

export const TextNotData = styled("div")`
    color: ${({theme }) => theme.text};
    font-weight: 400;
    font-size: 18px;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 961px) {
        margin: 0 20px 0 0;
        width: 100%;
        padding: 0 0 0 20px;
        margin-top: 15px;
    }
`;

export const TitleGrafic = styled("h1")`
    color: ${({theme }) => theme.text};
    margin-bottom: 33px;
    font-size: 1.2rem;

`;

export const ContainerGraficOne = styled("div")`
    margin: 20px 20px 20px 0px;
    border: 1px solid ${({theme }) => theme.text};
    border-radius: 8px;
    padding: 28px;
    width: 30%;
    min-width: 335px;

    @media (max-width: 610px) {
        margin: 10px 0 0 0;
        width: 90%;
    }
`;

export const Title = styled("h1")`
    font-weight: 800;
    font-size: 30px;
    margin-top: 8px;
    color: ${({theme }) => theme.text};

    @media (max-width: 961px) {
        margin: 20px 0 42px 0;
    }
`;

export const ContainerHeader = styled("header")`
    width: 100%;
    padding: 30px 60px 0 60px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 961px) {
        flex-direction: column;
        height: auto;
        padding-top: 20px;
        align-items: flex-start;
        padding: 20px 0 0 22px;
    }
`;

export const ContainerContent = styled("section")`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 60px 20px 60px;

    @media (max-width: 961px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }
`;

export const ContainerLoading = styled("div")`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconLoading = styled(BiLoaderCircle)`
    width: 45px;
    height: 45px;
    margin-top: 5px;
    color: ${({theme }) => theme.text};
    animation: loading 2s linear infinite;

    @keyframes loading {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ContainerNav = styled('nav')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    width: 100%;
    padding: 20px 20px 0 0;

    @media (max-width: 961px) {
        margin-top: 0;
        margin-bottom: 10px;
        overflow-x: auto;
        padding: 0 20px 0 0;
        width: 93%;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const ContainerItemNav = styled('div')<{ select: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${({theme }) => theme.text};
    border-radius: 8px;
    padding: 15px;
    margin-right: 20px;

    ${({ select }) => select && css`
      background: ${({ theme }) => theme.navSelect};
    `}
`;

export const ContainerButton = styled('div')``;

export const TextItemNav = styled('p')<{ select: boolean }>`
    color: ${({theme }) => theme.text};
    font-size: 1.2rem;
    margin-left: 10px;

    ${({ select }) => select && css`
       color: ${({theme }) => theme.background};
    `}
`;

export const SummaryStyle = styled('summary')`
    display: flex;
    background: ${({theme }) => theme.accordion.header};
    color: ${({theme }) => theme.accordion.text};
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    font-weight: 700;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    width: 80%;
    color: ${({theme }) => theme.text};
    outline: none;

    @media (max-width: 961px) {
       width: 92%;
    }

    &::-webkit-details-marker {
        display: none;
    }

    &:before {
        content: "+";
        font-size: 20px;
        font-weight: bold;
        margin: 0 5px;
        padding: 0;
        width: 20px;
        text-align: center;
    }

`;

export const ContainerTitleAndLogo = styled('div')`
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;

    @media (max-width: 961px) {
       flex-direction: column-reverse;
       align-items: flex-start;
    }
`;

export const TextDetails = styled('p')`
    padding: 20px 30px;
    background: ${({theme }) => theme.accordion.background};
    color: ${({theme }) => theme.text};
    line-height: 1.3;
    width: 80%;

    @media (max-width: 961px) {
       width: 92%;
    }
`;

export const DetailsStyle = styled('details')`
  display: block;
  width: 100%;
  margin: 10px 0;

  &[open] summary {
     border-top-right-radius: 8px;
     border-top-left-radius: 8px;
     border-bottom-right-radius: 0px;
     border-bottom-left-radius: 0px;
  }

  &[open] summary:before {
     content: "-";
     border-top-right-radius: 8px;
     border-top-left-radius: 8px;
     border-bottom-right-radius: 0px;
     border-bottom-left-radius: 0px;
  }

`;

export const ContainerStyle = styled('section')`
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;

   @media (max-width: 961px) {
       width: 100%;
       padding-left: 20px;
   }
`;