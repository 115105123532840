import { useNavigate } from 'react-router-dom'
import {
  Container,
  ContainerContent,
  ContainerHeader,
  SubTitle,
} from './styles'

const SuccessSendForm = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <ContainerHeader>
        <img src="./logo-final.png" alt="logo" />
      </ContainerHeader>
      <ContainerContent>
        <SubTitle>Respostas enviadas com sucesso, muito Obrigado!</SubTitle>
      </ContainerContent>
    </Container>
  )
}

export { SuccessSendForm }
