import { BiLoaderCircle } from 'react-icons/bi'
import styled from 'styled-components';

export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerHeader = styled("header")`
    width: 100%;
    height: 15vh;
    padding: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 680px) {
        padding: 60px 0 60px 20px;
    }
`;

export const TextAreaStyle = styled("textarea")`
   border: 1px solid ${({theme }) => theme.text};
   border-radius: 8px;
   width: 100%;
   outline: none;
   padding: 10px;
   line-height: 1.5;
   font-family: 'Roboto', sans-serif;
   font-size: 16px;
   color: ${({theme }) => theme.text};
   background-color: ${({theme }) => theme.background};
   -webkit-font-smoothing: antialiased;
   resize: none;
`;

export const ContainerContent = styled("section")`
    width: 100%;
    display: flex;
    padding-left: 60px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 50px;
    flex-direction: column;

    @media (max-width: 680px) {
        padding: 20px;
    }
`;

export const SubTitleItem = styled("p")`
    color: ${({theme }) => theme.text};
    font-size: 20px;
    font-weight: 400;
    margin: 40px 0 35px 0;
`;

export const ContainerNumber = styled("div")<{ $border: boolean }>`
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: ${( { theme } ) => theme.blockForm.background};
    border: ${({ $border, theme }) => $border ? `${theme.text} 2px solid` : 'none'};
    display: flex;
    cursor: pointer;
    margin-right: 10px;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
`;

export const IconLoading = styled(BiLoaderCircle)<{ color?: string }>`
    width: 30px;
    height: 30px;
    margin-top: 5px;
    color: ${({ color, theme }) => color ? color : theme.background};
    animation: loading 2s linear infinite;

    @keyframes loading {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ContainerSliceNumber = styled("div")`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const Item = styled("div")`

    padding: 30px 0;

    @media (max-width: 750px) {
         margin: 0 0 39px 0;
    }
`;
