import styled from "styled-components";

export const Container = styled("main")`
    width: 100%;
    height: 100vh;
    background-color: ${({theme }) => theme.background};

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ContainerHeader = styled("header")`
    width: 100%;
    height: 15vh;
    padding: 40px;
`;

export const ContainerContent = styled("section")`
    width: 100%;
    height: 85vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


export const SubTitle = styled("p")`
      color: ${({theme }) => theme.text};
    font-size: 19px;
    margin-top: 28px;
    margin-bottom: 40px;
    font-weight: 400;

    @media (max-width: 961px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        margin-left: 20px;
    }
`;
