import styled from 'styled-components';

export const InputBase = styled.input`
    width: 100%;
    outline: none;
    height: 45px;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 7px;
    background: ${({theme }) => theme.background};

    @media (max-width: 480px) {
        width: 90%;
    }
`;

export const ContainerInput = styled('div')<{mb: string}>`
    margin-bottom: ${({ mb }) => mb}px;
`;

export const LabelError = styled("label")<{colorLabel: string | undefined}>`
    color: ${({ colorLabel }) => colorLabel};
`;

export const Label = styled("label")`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.navSelect};
`;