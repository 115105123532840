import { InputBase, ContainerInput, LabelError, Label } from './styles'
import { PropsInput } from './type'

export const Input = ({
  error,
  type,
  placeholder,
  colorLabel,
  mb = '20',
  label,
  onChangerHandler,
  ...rest
}: PropsInput) => {
  return (
    <ContainerInput mb={mb}>
      <Label>{label}</Label>
      <InputBase
        onChange={onChangerHandler}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
      {error && <LabelError colorLabel={colorLabel}>{error}</LabelError>}
    </ContainerInput>
  )
}
